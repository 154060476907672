<template>
  <div class="form-floating">
    <select
      :id="id"
      :name="name"
      :disabled="disabled"
      :required="required"
    ></select>
    <label
      v-if="label.length > 0"
      class="form-label ps-8 mb-3 iam-label-select-floating required"
      v-bind:class="{
        'iam-label-select-floating-up':
          modelValue.length > 0 || placeholder.length,
      }"
      :for="id"
    >
      {{ label }}
    </label>
    <ErrorMessage
      :name="name"
      class="fv-plugins-message-container invalid-feedback"
    ></ErrorMessage>
  </div>
</template>

<script>
import $ from "jquery";
import { ErrorMessage } from "vee-validate";

export default {
  name: "combobox",
  data() {
    return {
      select2: null,
    };
  },
  components: {
    ErrorMessage,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: [String, Array], // previously was `value: String`
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {
        return {};
      },
    },
  },
  watch: {
    options: {
      handler(val) {
        this.setOption(val);
      },
      deep: true,
    },
    modelValue: {
      handler(val) {
        this.setValue(val);
      },
      deep: true,
    },
  },
  methods: {
    setOption(val = []) {
      this.select2.empty();
      this.select2.select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: val,
      });
      this.setValue(this.modelValue);
    },
    setValue(val) {
      if (val instanceof Array) {
        this.select2.val([...val]);
      } else {
        this.select2.val([val]);
      }
      this.select2.trigger("change");
    },
  },
  mounted() {
    this.select2 = $(this.$el)
      .find("select")
      .select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: this.options,
      })
      .on("select2:select select2:unselect", (ev) => {
        this.$emit("update:modelValue", this.select2.val());
        this.$emit("select", ev["params"]["data"]);
      })
      .on("select2:opening select2:closing", function () {
        // Quitar search en multiple
        const $searchfield = $(this).parent().find(".select2-search__field");
        $searchfield.prop("disabled", true);
      });
    this.setValue(this.modelValue);
  },
  beforeUnmount() {
    this.select2.select2("destroy");
  },
};
</script>
